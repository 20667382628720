import { Icon } from '~/components/ui/icon'

export const Social = () => {
  return (
    <>
      <a href="https://www.facebook.com/profile.php?id=61565134180801" className="hover:text-blue-400 text-[20px] mr-1" target="_blank" rel="noreferrer"><Icon name="gg:facebook" /></a>
      {/* <a href="https://linkedin.com" className="hover:text-blue-400 text-[20px] mr-1" target="_blank" rel="noreferrer"><Icon name="ri:linkedin-fill" /></a> */}
      <a href="https://www.instagram.com/bekir_safak/" className="hover:text-blue-400 text-[20px]" target="_blank" rel="noreferrer"><Icon name="mdi:instagram" /></a>
    </>
  )
}
